import React, {useEffect, useState} from "react";
import general from "../../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useScroll, useLockFn, useMount} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from '../../../lib/useActiveState';
import '../newgame/css/RecentGame.css'
import Subtitle from "../../components/Subtitle";

// export default ({active, type}) => {
export default props => {
  const {aplus_queue} = window;
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [obsolete, setObsolete] = useState();
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [init, setInit] = useState(false);

  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      type: 3,
      // game_species_type: type,
      game_species_type: 1,
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    await general.axios.post('/base/game/getGameList', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        setObsolete(false);
        parms.page && setPage(parms.page);
        let {game_list: list, paginated} = data;
        list.forEach(d => {
          d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
        })
        setGames(games => parms.page === 1 || !parms.page ? list : [...games, ...list]);
        setMore(paginated.more && list.length > 0);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  // useMount(() => load());
  // useEffect(() => {
  //   if (active) {
  //     load({page: 1});
  //   } else {
  //     setObsolete(true);
  //   }
  // }, [type]);
  // useEffect(() => {
  //   if (active && obsolete) {
  //     load({page: 1});
  //   }
  // }, [active, obsolete]);

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  //埋点
  const clickGameOfTheRankList = (game) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickGameOfTheRankList', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), tabname: '新游榜', gamename:game.game_name, agent: agent}]
    });
  }

  return <div className="game-new">
    {games.map((game, index) => <Link to={'/game?id=' + game.game_id} className="game-new-item" onClick={()=>clickGameOfTheRankList(game)}>
      <div className="index">
        {index < 3 ? <img src={'/resources/index/game-new-' + (index + 1) + '.png'}/> : index + 1}
      </div>
      <div className="icon">
        <Image src={game.game_image.thumb}/>
        {/* {game.discount < 1 ? <div className="discount">{game.discount * 10}折</div> : null} */}
        {
          game?.lablePosition == 2 && game.top_lable != '' ?
          <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
            {game.top_lable}
          </div>
          : null
        }
      </div>
      <div className="subject">
        <div className="text">
          <div className="name">
            {/* {game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name} */}
            <div className={" " + (game.nameRemark ? " name-new" : "")}>
              {game?.lablePosition == 1 && game.top_lable != '' ? <span className="span">{game.top_lable }</span> : null }
              {game.game_name}
            </div>
          </div>
          <div className="info1">
            {/* {
              game.nameRemark ?
              <span className="nameRemark">
                {game?.nameRemark}&nbsp;
              </span>
              : null
            } */}
            {/* {
              game.nameRemark ?
              <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
              :
              <>{game.game_classify_type.trim()}</>
            }
            {!game.nameRemark && game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null} */}
            {game.game_classify_type.trim()}
            <Subtitle>{game.nameRemark}</Subtitle>
            
          </div>
          {/* <div className="info2">
            {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag">{item}</div>) : <span style={{color: game.infoColor}}>{game.introduction}</span>}
          </div> */}
          <div className="info3">
            {
              game.bottom_lable ? 
              <>
              {Object.keys(game.bottom_lable).length > 0 ? 
              <div style={{display:'flex'}}>
                <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
              </div> : null }
              </>
              : null
            }
            {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
            {game.is_support_accele ? <div className="speed">可加速</div> : null}
            {game.is_easy_play ? <div className="speed">省心玩</div> : null}
            {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game.introduction}</span> : null}          </div>
        </div>
        {/* <div className="btn">{game.game_species_type != 3 ? '下载' : '开始'}</div> */}
        {game.discount < 1 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null}
      </div>
    </Link>)}
    {games.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
  </div>;
};