import React, {useEffect, useState} from "react";
import general from "../../general";
import $ from "jquery";
import {Link} from "react-router-dom";
import {useScroll, useThrottleEffect} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import useActiveState from '../../lib/useActiveState';
import Subtitle from "../components/Subtitle";

export default ({active, kw,refresh}) => {
  const {aplus_queue} = window;
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [recommendWords, setRecommendWords] = useState();
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      game_species_type: 0,
      is_search: true,
      search_info: kw,
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    general.axios.post('/base/searchGame/search', tmp)
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(response => {
          setInit(true);
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }
          setGames(games => parms.page === 1 || !parms.page ? result.gameList : [...games, ...result.gameList]);
          setMore(result.paginated.more);
          setRecommendWords(result.recommendWords);

          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['SearchDataSuccess', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), searchinfo: kw, agent: agent}]
          });
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (kw) {
      load({page: 1});
    }
  }, [kw, refresh]);

  const scroll = useScroll();
  useEffect(() => {
    if (active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, active]);

  return <div className={'search-result ' + (kw ? 'active' : '')}>
    <div className="search-empty">{recommendWords}</div>
    {games?.map(game => <Link to={'/game?id=' + game.game_id + '&source=searchHistory'} className="item">
      <div className="icon">
        <Image src={game.game_image.thumb}/>
        {/* <div className="type_label">
          {game.game_species_type == 1 ? 'BT' : game.game_species_type == 3 ? 'H5' : game.game_species_type == 2 && game.discount == 1 ? '官方' : ''}
        </div> */}
      </div>
      <div className="text">
        <div className="name">
          <div className="named" >
            {/* {game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name} */}
            <div className={" " + (game.nameRemark ? " name-new" : "")}>
              {game.top_lable != '' ? <span className="span">{game.top_lable }</span> : null }
              {game.game_name}
            </div>
          </div>
          <div className="authpic">
          {game.is_ocp == 1 ? <img src="/resources/search/new-auth.png" /> : null}
          </div>
        </div>
        <div className="info1">
          {/* {
            game.nameRemark ?
            <span className="nameRemark">
              {game?.nameRemark}&nbsp;
            </span>
            : null
          } */}
          {game.game_classify_type.trim()}
          <Subtitle>{game.nameRemark}</Subtitle>
          
        </div>
        {/* <div className="info2" style={{width: game.is_ocp == 1 ? '78%' : '100%'}}>
          {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag">{item}</div>) : game.introduction}
        </div> */}
        <div className="info3">
          {
            game.bottom_lable ? 
            <>
            {Object.keys(game.bottom_lable).length > 0 ? 
            <div >
              <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
            </div> : null }
            </>
            : null
          }
          {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
          {game.is_support_accele ? <div className="speed">可加速</div> : null}
          {game.is_easy_play ? <div className="speed">省心玩</div> : null}
          {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game.introduction}</span> : null}        </div>
        {/* {game.discount < 1 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null} */}
      </div>
      {/* {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/new-discount-text-bg.png)'}}><div className="number"><span>{(game.discount * 10).toFixed(1)}</span>折</div></div> : null} */}

      {
        game.game_species_type != 1  || (game.game_species_type == 1 && game.discount < 1 && game.discount > 0) ?
        <div className="discount" style={{backgroundImage:'url(/resources/game/new-discount-text-bg.png)'}}>
          <div className="number">
            {(()=>{
              if(game.game_species_type == 3) {
                return 'H5';
              } else if(game.discount < 1 ) {
                return <span>{(game.discount * 10)}折</span>
              } else {
                return '官方'
              }
            })()}
          </div>
        </div>
        : null
      }

      
      {/* <div className="btn">{game.game_species_type != 3 ? '下载' : '开始'}</div> */}
    </Link>)}
    
    {games?.length === 0 && init ? <div className="search-empty">
      {/* <img src="/resources/search/empty.png" alt=""/>
      搜索"{kw}"没有找到任何数据 */}
      {/* #暂无该游戏，为你推荐相似游戏# */}
    </div> : null}
    {games?.length > 6 && !more? <div className="view-end">别撩啦！已经到底啦~</div> : null}
  </div>;
};