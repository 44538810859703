import React, {useEffect, useState} from "react";
import URI from "urijs";

function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
    break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
    break;
    default:
      return 0;
    break;
  }
}

export default props => {
  const [initProps, setInitProps] = useState({});
  const [newUrl, setNewUrl] = useState();
  const [progress, setProgress] = useState(randomNum(25, 50));
  useEffect(() => {
    setInitProps({user: true, token: true, ...props});
  }, [props]);
  useEffect(() => {
    let url = URI(props.valueInfo)
            .addQuery('username', localStorage.getItem('username'))
            .addQuery('token', localStorage.getItem('token'))
            .addQuery('location_url', window.location.origin)
            .addQuery('origin', 'react')
            .toString();
            
    setNewUrl(url);
  }, [initProps]);

  return <div className="view-iframe" style={{paddingTop:'calc(env(safe-area-inset-top)'}}>
  <div className={'progress ' + (progress === 100 ? 'loaded' : 'loading')} style={{width: progress + '%'}}/>
  <iframe src={newUrl} onLoad={() => setProgress(100)}/>
</div>;
};
