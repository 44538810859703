import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Dialog from "../../lib/Dialog";
import general from "../../general";
import {useLockFn, useScroll, useUpdate} from "ahooks";
import Image from "../../lib/Image";

export default props => {
  useEffect(() => {
    props.setOperate(<Link to="/task/tryplay-explain"
                           className="task-tryplay-operate"><img src="resources/task/question.png" alt="" style={{width:'20px'}}/></Link>);
  }, []);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [datas, setDatas] = useState([]);
  const [init, setInit] = useState(false);

  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    general.axios.post('/user/tryPlay/getList',{topic_id:props.query.topic_id ? props.query.topic_id : '',pagination: {count: 100, page: 1}})
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        setLoaded(true);
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setDatas(data.list.map(item => {
          item.s = item.surplus / item.total;
          if (item.s > 1) {
            item.s = 1;
          }
          if (!item.s) {
            item.s = 0;
          }
          return item;
        }));
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  useEffect(()=>{
    if (!props.active){
      setInit(false)
    }
  },[props.active])
  useEffect(()=>{
    if (loaded && datas.length > 0) {
      setInit(true)
    }
  },[datas,loaded])
  useEffect(()=>{
    const timer = setInterval(() => {
      if (!init){
        clearInterval(timer)
      }else {
        countdown(datas)
      }
    }, 1000);
    return ()=>{
      clearInterval(timer)
    }
  },[init,datas])
  const countdown = (datas)=>{
    console.log(datas);
      datas.map(item =>{
      let surplusTime = parseInt(item.surplusTime);
      if (surplusTime > 0){
        surplusTime -= 1;
      }else{
        surplusTime = 0;
      }
      item.surplusTime = surplusTime;
    })
    setDatas([...datas])
  }
  useEffect(() => {
    if (props.active && !loaded) {
      load();
    }
  }, [props]);
  const scroll = useScroll();
  useEffect(() => {
    if (props.active && loaded) {
      if (scroll.top < -80) {
        load({page: 1});
      }
    }
  }, [scroll, props.active]);

  const update = useUpdate();

  const receiveTask = useLockFn(async data => {
    Dialog.loading();
    general.axios.post('/user/tryPlay/receiveTask', {id: data.id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        // data.is_receive = true;
        setLoaded(false)
        load()
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  const receiveReward = useLockFn(async data => {
    Dialog.loading();
    await general.axios.post('/user/tryPlay/receiveReward', {id: data.id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        // data.is_receive_coin = true;
        // update();
        load()
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  return <div className="task-tryplay" >
    <div className="tryplay-head" style={{backgroundImage:"url(/resources/welfare/try_bg.png)"}}>
      <h1>任务步骤</h1>
      <p>1.领取任务</p>
      <p>2.创建小号，达到试玩要求</p>
      <p>3.返回该页面领取奖励</p>
      <div>注意：需先领取任务后，再创建小号进行试玩，任务才可生效</div>
    </div>
    <div className="tryplay-body">
      {datas.map((item, index) => <Link to={'/game?id='+item.appid+'&mid=' + item.gameid} className="obj">
        <div className="item">
          <div className="icon" style={{borderRadius:'13px'}}><Image src={item.game_logo}/></div>
          <div className="info">
            <div className="name">
              <div className={" " + (item.nameRemark ? " name-new" : "")}>
                {item.game_name}
              </div>
            </div>
            <div className="type">
              {item.game_classify_type.trim()}
              {item.nameRemark ? <span className="nameRemark-new">·&nbsp;&nbsp;{item.nameRemark}</span> : null}
            </div>
            <div className="type">
              已参与：
              <span>{item.receivedNum}人（已有{item.completed_quantity}人完成）</span>
            </div>
          </div>
          <div className="operate">
            {(() => {
              if (item.is_receive) {
                if (item.is_complete) {
                  if (item.is_receive_coin) {
                    return <div className="btn success">已完成</div>;
                  } else {
                    return <div className="btn receive" onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      receiveReward(item);
                    }}>领取奖励</div>;
                  }
                } else {
                  if (item.surplusTime > 0){
                    return <div className="btn ing">进行中</div>;
                  }else{
                    return <div className="btn disable">未完成</div>;
                  }
                }
              } else if (item.surplus) {
                return <div className="btn" onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  receiveTask(item);
                }}>领任务</div>;
              } else {
                return <div className="btn disable">已领完</div>;
              }
            })()}
          </div>
        </div>
        <div className="reward">
          <div className="time">
            { item.is_receive && !item.is_complete && !item.is_receive_coin && item.surplusTime > 0 ? 
            <div>
              <span>活动剩余时间：</span>
              <span><span className="value">{parseInt(item.surplusTime/60/60).toString().padStart(2,'0')}</span><i>:</i><span className="value">{parseInt(item.surplusTime / 60 % 60).toString().padStart(2,'0')}</span><i>:</i><span className="value">{(item.surplusTime % 60).toString().padStart(2,'0')}</span></span>
            </div> : <div>限时要求：自领取后{item.second/3600}小时内完成</div>}
          </div>
          <span>试玩奖励：<span className="award">{item.reward}</span></span>
        </div>
        <div className="content">
          <div className="desc">任务要求：</div>
          <div className="require">{item.require}</div>
        </div>
      </Link>)}
    </div>
  </div>;
};