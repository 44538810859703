import {useEffect, useState} from "react";
import {useLockFn} from "ahooks";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import {useHistory, useLocation} from "react-router-dom";
import URI from "urijs";

export default ({datas, setPay, vip}) => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState();
  const [isCheckAdult, setIsCheckAdult] = useState(false); //未成年温馨提示状态
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const [user, setUser] = useActiveState('user');
  const [config] = useActiveState('config');
  const [agent] = useActiveState('agent');

  useEffect(() => {
    !data && setData(datas.find(item => item.active));
  }, [datas]);

  //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
      Dialog.loading();
      general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          
          if (!status.succeed) {
            Dialog.info(status.error_desc);
          } else {
            Dialog.info("认证成功");
            setIsCheckAuth(false);
            // setUser({...user, isRealNameAuth: true});
            setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
    })

  const cardPay = useLockFn(()=>{
    if(!general.isLogin()) {
      history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
      return;
    }

    if(user.isCheckAuth && !user.isRealNameAuth) {
      setIsCheckAuth(true);
      return;
    }
    if(user.isCheckAdult && !user.isAdult) {
      setIsCheckAdult(true);
      return;
    }
    setPay({type: 'mcard', grade_id: data?.grade_id})

    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['BuyMoneySavingCard', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
    });
  })

  return <div><div className="save-card" style={{backgroundImage: 'url(/resources/save/card-bg.png)'}}>
    <div className="card-type">
      {datas.map(item => ((vip == 1 && item.active) || vip != 1) ? <div className={'item' + (item.grade_id === data?.grade_id ? ' active' : '')} onClick={() => setData(item)} style={{backgroundImage: 'url(/resources/save/card-item.png)'}}>
        {/* {item.tips ? <div className="vip"><img src="/resources/save/card-vip.png" alt=""/></div> : null} */}
        {item.hot_title ? <div className="good" style={{backgroundImage: 'url(/resources/save/vip-good.png)'}}>{item.hot_title}</div> : null}
        <div className="title">{item.title}</div>
        <div className="price">￥<span>{item.price}</span></div>
        <div className="value">{item.desc}</div>
        <div className="choose"><img src="/resources/save/card-choose.png" alt=""/></div>
      </div> : null)}
    </div>
    <div className="card-btn" onClick={cardPay}>{data?.submit_button_title || '立即开通'}</div>
    <div className="card-tips">{data?.tips}</div>
    <div className="card-info">
      <div className="title">省钱卡权益</div>
      <div className="items">
        <div className="item">
          <img src="/resources/save/card-1.png" alt=""/>
          满6减6券＊每天1张
        </div>
        <div className="item">
          <img src="/resources/save/card-2.png" alt=""/>
          支持全部BT游戏
        </div>
      </div>
    </div>
    <div className="card-explain">
      <div className="title">购买说明</div>
      <div className="content">
        <dl>
          <dt>·面向用户：</dt>
          <dd>全体用户；</dd>
        </dl>
        <dl>
          <dt>·购买说明：</dt>
          <dd>
            {/* {data?.buy_tips ? <span>{data.buy_tips}<br/></span> : null} */}
            {/* <span>购买省钱月卡可免费领取至尊版特权，拒绝频繁掉签烦恼，使用更加稳定；</span> */}
            {/* 开通成功后，当日立即获得满6减6元代金券，之后每日01:10分发放1张代金券。 */}
            <p>开通成功后，当日立即获得满6减6元代金券，之后每日0:10分发放1张代金券。</p>
          </dd>
        </dl>
        <dl>
          <dt>·有效时间：</dt>
          <dd>代金券有效期7天；</dd>
        </dl>
        <dl>
          <dt>·续费说明：</dt>
          <dd>
            {/* 在有效期内续费，月卡／周卡／季卡时间将延长，有效期内每日发放1张代金券；过期后续费，续费成功后，当日立即获取1张代金券，之后有效期内每日发放1张代金券 */}
            续费后月卡/周卡时间将延长，在月卡/周卡有效期内每日发放1张代金券。月卡/周卡发放数量有限。
            {/* 续费后月卡／周卡／季卡时间将延长，在月卡／周卡／ 季卡有效期内每日发放1张代金券。月卡／周卡／季 卡发放数量有限。 */}
          </dd>
        </dl>
      </div>
    </div>
  </div>
  {/***实名认证弹框***/}
  {isCheckAuth?
  <div className="pay-check-auth">
      <div className="auth-index">
        <div className="close" onClick={() => setIsCheckAuth(false)}>
          <img src="/resources/user/close.png" />
        </div>
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>实名认证</h3>
            <p>
            {config.authTopTips}
              {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
            </p>
          </div>
        </div>

        <div className="auth-user-realname">
          <div className="auth-user-input">
            <div className="name">真实姓名</div>
            <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
          </div>
          <div className="auth-user-input">
            <div className="name">身份证号</div>
            <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
          </div>
          <span>
          {config.authBottomTips}
          {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
          </span>
          <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
        </div>
      </div>
    </div> : null
    }
    {/** 提示弹框 **/}
    {
    isCheckAdult ? 
    <div className="pay-check-auth">
      <div className="auth-index adult-index">
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>温馨提示</h3>
            <p>
            {config.adultTips}
              {/* 根据国家新闻出版署最新规定，禁止向未成年用户提供支付服务。 */}
            </p>
          </div>
        </div>
        <div className="auth-user-realname">
          <div className="auth-user-btn" onClick={()=>setIsCheckAdult(false)}>我知道了</div>
        </div>
      </div>
    </div>
    : null
    }

  </div>;
};