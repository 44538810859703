import {useEffect, useState, useRef, useCallback} from "react";
import {useLockFn, useSize, useUpdateEffect, useLocalStorageState, useScroll} from "ahooks";
import general, {redux} from "../../general";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from "swiper";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useHistory, useLocation} from "react-router-dom";
import URI from "urijs";

SwiperCore.use([Navigation]);

const vipequity = [
  {
    id: 'rebatePtb',
    title: '等额返还',
    intro: '购买相应等级会员后立即等额返还相应平台币',
    object: '子爵/伯爵/公爵/国王/皇帝',
  },
  {
    id: 'auth',
    title: '身份标识',
    intro: '会员有效期内，个人中心及评论专区享有专属会员标识，彰显您尊贵的身份',
    object: '子爵/伯爵/公爵/国王/皇帝',
  },
  {
    id: 'coinAdd',
    title: '金币加成',
    intro: '会员签到可获得的额外奖励',
    object: '子爵/伯爵/公爵/国王/皇帝',
  },
  {
    id: 'birthdayGift',
    title: '生日礼',
    intro: '会员尊享礼遇，生日当天会员用户可获得1张无门槛代金券',
    object: '子爵/伯爵/公爵/国王/皇帝',
  },
  {
    id: 'vipchannel',
    title: '客服VIP通道',
    intro: '咨询客服快人一步，专享绿色客服通道，快速响应。全程为您贴心跟进事情处理进度，全方位为您服务。',
    object: '国王/皇帝',
  },
  {
    id: 'gift',
    title: '特权礼包',
    intro: '平台会不定期配合游戏商发行一些高价值会员礼包，公爵以上的会员可免费领取。会员等级越高，可免费领取的游戏礼包越多。游戏详情页礼包处会标注该礼包的领取权限，您可以前往游戏详情页查看和领取。',
    object: '公爵/国王/皇帝',
  },
  {
    id: 'active',
    title: '专属活动',
    intro: '平台会不定期推出会员专属活动，会员可独享更多福利优惠（具体视活动规则而定），敬请期待。',
    object: '国王/皇帝',
  },
  {
    id: 'buyAccountRebate',
    title: '购号返还',
    intro: '皇帝会员专享购买账号，按照账号销售金额的3%的返还金币',
    object: '皇帝',
  }
];

const swipers = [];
export default ({datas, setPay, vipParam, packs}) => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState();
  const [isCheckAdult, setIsCheckAdult] = useState(false); //未成年温馨提示状态
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const [user, setUser] = useActiveState('user');
  const [config] = useActiveState('config');
  const [vipPopState, setVipPopState] = useState(false);
  const [agent] = useActiveState('agent');

  useEffect(() => {
    !data && setData(datas.find(item => item.active));
  }, [datas]);

  // console.log(vipParam);

    //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          // setUser({...user, isRealNameAuth: true});
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
    })

  const cardPay = useLockFn(()=>{
    if(!general.isLogin()) {
      history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
      return;
    }

    if(user.isCheckAuth && !user.isRealNameAuth) {
      setIsCheckAuth(true);
      return;
    }
    if(user.isCheckAdult && !user.isAdult) {
      setIsCheckAdult(true);
      return;
    }
    setPay({type: 'vip', grade_id: data?.grade_id})

    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['BuyMembershipCard', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
    });
  })


  const ref = useRef(null);
  const size = useSize(ref);
  const [navFixed, setNavFixed] = useState(0);
  const navRef = useRef();
  const navSize = useSize(navRef);
  const navPlaceRef = useRef();
  const [routerIndex, setRouterIndex] = useState(0);

  useUpdateEffect(() => {
    if (init) {
      setNavFixed(scroll.top + navSize.height > $(navPlaceRef.current).offset().top);
    }
  }, [scroll.top, size]);

  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(routerIndex, 0, false);
    }
  }, [routerIndex, swiper]);

  const Ref = useCallback(node => {
    if (node) {
      node.addEventListener('touchmove', e => {
        e.preventDefault();
      }, {passive: false});
    }
  }, []);

  const imgRef = useCallback(node => {
    if (node) {
      node.addEventListener('touchstart', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchmove', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchend', e => {
        e.stopPropagation();
      }, {passive: false});
    }
  }, []);

  //滑动动画
  const tagRef = useCallback(node => {
    if (node) {
      if (
        node.parentNode.scrollLeft + node.parentNode.offsetWidth < node.offsetLeft
        || node.parentNode.scrollLeft + node.parentNode.offsetWidth + node.offsetWidth > node.offsetLeft
      ) {
        $(node.parentNode).animate({scrollLeft: node.offsetLeft - node.parentNode.offsetWidth / 2}, 555);
        node.parentNode.scrollLeft = node.offsetLeft - node.parentNode.offsetWidth / 2;
      }
    }
  }, []);
  
  return <div>
      <div className="save-vip">
      <div className="vip-title" style={{backgroundImage: 'url(/resources/save/vip-title.png)'}}>会员套餐</div>
      <div className="vip-select">
        <div className="vip-type">
          {datas.map(item => <div className={'item' + (item.grade_id === data?.grade_id ? ' active' : '')} onClick={() => setData(item)}>
            {item.active ? <div className="good"><img src="/resources/save/vip-good.png" alt=""/></div> : null}
            <div className="title">{item.title}</div>
            <div className="price">￥<span>{item.price}</span></div>
            <div className="name">{item.grade_name}</div>
            <div className="ptb">{item.give}</div>
            <div className="choose"><img src="/resources/save/vip-choose.png" alt=""/></div>
          </div>)}
        </div>

        {data?.bottom_tips ?
        <div className="tips">
          <img src="/resources/save/tips.png" alt="" />
          {data?.bottom_tips}
        </div>
        : null}
      </div>
      <div className="vip-btn" onClick={cardPay}>{data?.submit_button_title || '立即开通'}</div>
      <div className="vip-title" style={{backgroundImage: 'url(/resources/save/vip-title.png)'}}>会员特权</div>
      <div className="vip-info">
        {
          data?
            data.privilege.map((item, index)  => <div className={"item "} >
              <img src={'/resources/save/' + item.name + '-' + item.active + '.png'} onClick={() => {
                  setVipPopState(true);
                  setRouterIndex(index);
                }} />
            <div>
            <h1>{item.title}</h1>
            </div>
          </div>)
          :
          null
        }
      </div>
      <div className="vip-future" style={{backgroundImage: 'url(/resources/save/vip-future.png)'}}>更多福利特权 敬请期待</div>
    </div>

    {vipPopState ? 
    (data ?
    <div className="vip-index-intro" >
      <div className="vip-site">
        <div className="vip-header-main">
          <div className={'vip-nav clearfix'}>
            <div className="container">
              {data.privilege.map((item, index) => {
                return <div
                  className={'vip-nav-item vip-nav-' + index + ' ' + (routerIndex == index ? 'active' : '')}
                  onClick={() => {
                    setRouterIndex(index)
                  }}
                  ref={routerIndex == index ? tagRef : null}
                >
                  {(() => {
                    if(routerIndex == index) {
                      // return <img src={'/resources/save/' + item.name + '-' + item.active + '.png'}/>
                      if(item.active) {
                        return <img src={'/resources/save/' + item.name + '-' + 'true.png'}/>
                      } else {
                        return <img src={'/resources/save/' + item.name + '-' + 'true-dark.png'}/>
                      }
                      
                    } else {
                      if(item.active) {
                        return <img src={'/resources/save/' + item.name + '-' + 'false-dark-none.png'}/>
                      } else {
                        return <img src={'/resources/save/' + item.name + '-' + 'false-dark.png'}/>
                      }
                      // return <img src={'/resources/save/' + item.name + '-' + item.active + '-dark.png'}/>
                      return <img src={'/resources/save/' + item.name + '-' + 'false-dark.png'}/>
                    }
                  })()}
                  {item.title}
                </div>
              })}
            </div>
          </div>
        </div>
        
        <div className="vip-pop-main">
          <Swiper
            onSwiper={setSwiper} 
            onSlideChange={swiper => setRouterIndex(swiper.activeIndex)}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              disabledClass: 'disable'
            }}
          >
            {data.privilege.map((item,index) => <SwiperSlide>
              <div className="intro-pop-section" style={{backgroundImage: 'url(/resources/save/' + item.name + '-bg' + '.png)'}}>
                <div className="vip-intro-and-object">
                  <div className="vip-intro">
                    <img src='/resources/save/vip-intro.png' className="img" />
                    <div className="">
                      {(() => {
                        if(item.name === 'gift') {
                          return <div className="container" ref={imgRef}>
                          {packs.map(pack => <Link to={"/game?id=" + pack.appid}>
                              <div className="game-item">
                                <div className="icon"><img src={pack.logo}/></div>
                                <div className="name">{pack.gamename}</div>
                                <div className="time">{pack.packname}</div>
                              </div></Link>
                          )}
                          </div>
                        }
                      })()}
                      {vipequity[index].intro}

                      {(() => {
                        if (item.name === 'coinAdd') {
                          return <div className="coin-table">
                              {/* <table>
                                <thead>
                                <tr>
                                    <th>会员级别</th>
                                    <th>签到</th>
                                    <th>每日分享</th>
                                    <th>每日充值</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>子爵</td>
                                    <td>N+1</td>
                                    <td>N+1</td>
                                    <td>N+1</td>
                                </tr>
                                <tr>
                                    <td>伯爵</td>
                                    <td>N+2</td>
                                    <td>N+2</td>
                                    <td>N+2</td>
                                </tr>
                                <tr>
                                    <td>公爵</td>
                                    <td>N+3</td>
                                    <td>N+3</td>
                                    <td>N+3</td>
                                </tr>
                                <tr>
                                    <td>国王</td>
                                    <td>N+5</td>
                                    <td>N+5</td>
                                    <td>N+5</td>
                                </tr>
                                <tr>
                                    <td>皇帝</td>
                                    <td>N+20</td>
                                    <td>N+20</td>
                                    <td>N+20</td>
                                </tr>
                                </tbody>
                            </table> */}
                            <img src="/resources/save/sign-reward.png" />
                            <span className="text-vip-i">N代表完成对应任务普通用户所获金币</span>
                          </div>
                        } else if(item.name === 'birthdayGift') {
                          if(vipParam.is_vip) {
                            return <div>
                              <div className="birthday-img">
                                <img src={'/resources/save/birthday-'+ vipParam.birthdayVoucherAmount +'.png'} />
                              </div>
                              <span className="text-vip-i">前提是您必须先完成实名认证，系统会根据您身份证上的出生日
                                期作为判断依据，会员有效期内，生日当天系统会自动给您发放
                                生日代金券，届时请前往我的页面代金券处查看。</span>
                            </div>
                          } else {
                            return <div>
                              <p>子爵：3元无门槛代金券</p>
                              <p>伯爵：6元无门槛代金券</p>
                              <p>公爵：10元无门槛代金券</p>
                              <p>国王：20元无门槛代金券</p>
                              <p>皇帝：50元无门槛代金券</p>
                              
                              <span className="text-vip-i">前提是您必须先完成实名认证，系统会根据您身份证上的出生日
                                期作为判断依据，会员有效期内，生日当天系统会自动给您发放
                                生日代金券，届时请前往我的页面代金券处查看。</span>
                            </div>
                          }
                           
                        } else if(item.name === 'rebatePtb') {
                          return <div>
                            <p>子爵 返还980平台币</p>
                            <p>伯爵 返还2880平台币</p>
                            <p>公爵 返还4990平台币</p>
                            <p>国王 返还9990平台币</p>
                            <p>皇帝 返还99990平台币</p>
                            <span className="text-vip-i">（1元=10平台币）</span>
                          </div>
                        }
                      })()}
                    </div>
                  </div>
                  <div className="vip-object">
                    <img src='/resources/save/vip-object.png' className="img"/>
                    <div className="">
                      {vipequity[index].object}
                      {(() => {
                        if(item.name === 'birthdayGift') {
                          if(!user.isRealNameAuth) {
                            return <div>
                              <div className="vip-pop-button" onClick={()=>{setIsCheckAuth(true);setVipPopState(false)}} style={{backgroundImage: 'url(/resources/save/pop-but-bg.png)'}}>实名认证></div>
                            </div>
                          }
                        } else if(item.name === 'buyAccountRebate') {
                          return <Link to='/trade'>
                            <div className="vip-pop-button" style={{backgroundImage: 'url(/resources/save/pop-but-bg.png)'}}>低价购买氪金号></div>
                          </Link>
                        } else if(item.name === 'vipchannel') {
                          return <Link to='/service'>
                            <div className="vip-pop-button" style={{backgroundImage: 'url(/resources/save/pop-but-bg.png)'}}>联系客服></div>
                          </Link>
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>)}
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </Swiper>
        </div>
        <div className="close" onClick={()=>{setRouterIndex(0);setVipPopState(false)}}>
          <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" />
        </div>
      </div>
    </div>
    :null)
    :null}


    {/***实名认证弹框***/}
    {isCheckAuth?
    <div className="pay-check-auth">
        <div className="auth-index">
          <div className="close" onClick={() => setIsCheckAuth(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>实名认证</h3>
              <p>
              {config.authTopTips}
                {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
              </p>
            </div>
          </div>

          <div className="auth-user-realname">
            <div className="auth-user-input">
              <div className="name">真实姓名</div>
              <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
            </div>
            <div className="auth-user-input">
              <div className="name">身份证号</div>
              <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
            </div>
            <span>
            {config.authBottomTips}
            {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
            </span>
            <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
          </div>
        </div>
      </div> : null
      }
      {/** 提示弹框 **/}
      {
      isCheckAdult ? 
      <div className="pay-check-auth">
        <div className="auth-index adult-index">
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>温馨提示</h3>
              <p>
              {config.adultTips}
                {/* 根据国家新闻出版署最新规定，禁止向未成年用户提供支付服务。 */}
              </p>
            </div>
          </div>
          <div className="auth-user-realname">
            <div className="auth-user-btn" onClick={()=>setIsCheckAdult(false)}>我知道了</div>
          </div>
        </div>
      </div>
      : null
      }
  </div>;
};